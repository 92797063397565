import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import filterReducer from "./reducers/filterReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const initialState = {
  sidebarShow: true,
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const filterPersistConfig = {
  key: "filterData",
  storage, 
};

const persistedFilterReducer = persistReducer(filterPersistConfig, filterReducer);

export const store = configureStore({
  reducer: {
    userData: userReducer,
    changeState: changeState,
    filterData: persistedFilterReducer, 
  },
});


export const persistor = persistStore(store);

export default store;
