import { axiosPrivate } from "./axios";

export const createOrderApi = async (payloadData) => {
  let res = await axiosPrivate.post("/order/save", payloadData);
  return res.data;
};

export const fetchCustomerListApi = async () => {
  let res = await axiosPrivate.get(`/order/customers`);
  return res.data;
};

export const fetchCustomerListApi1 = async (value) => {
  let res = await axiosPrivate.get(`/order/customers?search=${value}`);
  return res.data;
};


export const orderListApi = async (
  userId="",
  offset="",
  fromDate="",
  toDate="",
  customerId="",
  isSent=""
) => {
  let res = await axiosPrivate.get(
    "/order/list?userId=" +
    userId +
    "&offset=" +
    offset +
    "&customerId=" +
    customerId +
    "&fromDate=" +
    fromDate +
    "&toDate=" +
    toDate + "&isSent=" +
    isSent
  );
  return res.data;
};
export const deleteorderByIdApi = async (payloadData) => {
  let res = await axiosPrivate.post("/order/delete", payloadData);
  return res.data;
};
export const orderEditByIdApi = async (orderId) => {
  let res = await axiosPrivate.get("/order/edit?orderId=" + orderId);
  return res.data;
};
export const OrderPhotoUpdate = async (payloadData) => {
  let res = await axiosPrivate.post("/order/upload/photo", payloadData);
  return res.data;
};

export const OrderSendSms = async (payloadData) => {
  let res = await axiosPrivate.post("/order/send-sms", payloadData);
  return res.data;
}
export const OrderUpdate = async (payloadData) => {
  let res = await axiosPrivate.post("/order/update", payloadData);
  return res.data;
}
export const deleteOrderItem = async (orderItemId) => {
  let res = await axiosPrivate.post("/order/delete/item", orderItemId);
  return res.data;
}
export const ItemCustomerList = async (val) => {
  let res = await axiosPrivate.get(`/order/items?search=${val}`);
  return res.data;
}
export const getOrderPrint = async (orderId) => {
  let res = await axiosPrivate.get("/order/print?orderId=" + orderId);
  return res.data;
}
export const checkIsReceived = async (tenantId, data) => {
  let res = await axiosPrivate.post("/order/check-isReceived-flag", data);
  return res.data;
}

export const ItemNotPickedList = async (
  userId,
  offset,
  fromDate,
  toDate,
  customerId,
  isSent
) => {
  let res = await axiosPrivate.get(
    "/weakago/order/list?userId=" +
    userId +
    "&offset=" +
    offset +
    "&customerId=" +
    customerId +
    "&fromDate=" +
    fromDate +
    "&toDate=" +
    toDate + "&isSent=" +
    isSent
  );
  return res.data;
};


export const ReceivedOrderList = async (
  userId,
  offset,
  fromDate,
  toDate,
  customerId,
  isSent
) => {
  let res = await axiosPrivate.get(
    "/received/order/list?userId=" +
    userId +
    "&offset=" +
    offset +
    "&customerId=" +
    customerId +
    "&fromDate=" +
    fromDate +
    "&toDate=" +
    toDate + "&isSent=" +
    isSent
  );
  return res.data;
};