import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CInputGroup,
  CRow,
  CHeaderDivider,
  CBreadcrumb,
  CBreadcrumbItem
} from "@coreui/react";
import { fetchCustomerListApi } from "../Helper/order";
import { useSelector } from "react-redux";
import { customerApi } from "../Helper/customer";
import { useParams } from "react-router-dom";
import { orderEditByIdApi } from "../Helper/order";
import Select from "react-select";



function View() {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState();
  const [formFields, setFormFields] = useState([
    { item: "", charges: "", type: "" },
  ]);
  const [customernameError, setCustomernameError] = useState(false);
  const [chargestotal, setchargestotal] = useState();
  const [customerlist, setCustomerList] = useState([]);
  const [validated, setValidated] = useState(false);
  const [note, setNote] = useState("");
  const [Date, setDate] = useState();
  const [selectedCustomer, setSelectedCusomer] = useState();
  const [OrderPhoto, setOrderPhoto] = useState();
  let { Id } = useParams();

  useEffect(() => {
    getorderDataForEdit();
  }, []);
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    if (image.type === "image/png" || image.type === "image/jpeg") {
      setPreview(objectUrl);
    } else {
      setPreview(null);
    }

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);


  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
    if (event.target.name == "charges") {
      let totalcharges = 0;
      formFields.map((e) => {
        totalcharges = totalcharges + parseFloat(e.charges);
      });
      setchargestotal(totalcharges);
      // console.log("tota",totalcharges)
    }
  };



  const getorderDataForEdit = async () => {
    let orderId = Id;
    await orderEditByIdApi(orderId)
      .then(
        async (res) => {
          // console.log("res", res.data);
          if (res.status === 200) {
            var items = [];
            res.data.orderItems.forEach((element) => {
              items.push({
                item: element.item,
                type: element.type,
                charges: element.charges,
              });
              setFormFields(items);
            });
           
            setNote(res.data.note);
            setDate(res.data.orderDate);
            setSelectedCusomer({ value: res?.data?.customerId, label: res?.data?.customerName });
            setchargestotal(res.data.totalCharges);
            setOrderPhoto(res.data.orderPhoto);
          }
        },
        (err) => { }
      )
      .catch();
  };

  function handleCustomer(e) {
    setSelectedCusomer(e.value);
    setCustomernameError("");
  }

  return (
    <div>
      <CHeaderDivider />

      <CRow className="m-2 mb-0">
        <CCol xs={12}>
          <h5 className="main-title">View </h5>
        </CCol>
        <CCol xs={8}>
          <CBreadcrumb
            className="m-0 ms-2"
            style={{ "--cui-breadcrumb-divider": "'>'" }}
          >
            <CBreadcrumbItem>

              <Link to="/dashboard">Home</Link>
            </CBreadcrumbItem>
            <CBreadcrumbItem actives>View Order</CBreadcrumbItem>
          </CBreadcrumb>
        </CCol>
        <CCol xs={4}>
          <div className="text-end">
            {/* <Link to="/customerbilling"> */}
            <CButton
              color="info"
              size="sm"
              className="px-4 text-end text-white "
              type="button"
              style={{ marginTop: "-52px" }}
              onClick={() => window.history.back()}
            >
              Back
            </CButton>
            {/* </Link> */}
          </div>
        </CCol>
      </CRow>

      <br />

      <CRow className="justify-content-start">
        <CCol md={12} sm={12} lg={12}>
          <CCard style={{ margin: "10px" }} className="mb-4">
            <CCardHeader>
              <strong>View</strong>
            </CCardHeader>
            <CCardBody>
              <br />
              <div>
                <CForm
                  className="row g-3 needs-validation"
                  noValidate
                  validated={validated}
                  method="post"
                >
                  <CRow className="mb-3">
                    <CCol md={7} sm={12} xs={12}>
                      <CFormLabel>Customer</CFormLabel>

                      <div className="">
                        <Select
                          options={customerlist}
                          isDisabled={true}
                          className="text-start"

                          placeholder={
                            <div className="select-placeholder-text">
                              Select options
                            </div>
                          }
                          value={selectedCustomer}
                          onChange={handleCustomer}
                          isSearchable={true}
                        />
                      </div>
                      <CFormFeedback invalid>
                        Please Choose a Customer Name.
                      </CFormFeedback>
                      {customernameError === true ? (
                        <>
                          <CFormFeedback className="errorMessage mb-3">
                            Please Choose a Customer Name.
                          </CFormFeedback>
                        </>
                      ) : null}
                    </CCol>
                    <CCol md={1} sm={1} xs={2}>
                    </CCol>

                    <CCol lg={3} md={6} sm={5}>
                      <CFormLabel>Order Date</CFormLabel>
                      <CInputGroup className="mb-3">
                        <CFormInput
                          type="date"
                          name="date"
                          className="text-dark"
                          value={Date}
                          disabled
                          onChange={(e) => {
                            setDate(e.target.value);
                          }}
                          placeholder="Date"
                        />
                      </CInputGroup>
                    </CCol>

                  </CRow>

                  {formFields.map((form, index) => {
                    return (
                      <CRow key={index}>
                        <CCol md={4} sm={12} xs={12}>
                          <CFormLabel htmlFor="validationCustom01">
                            Item
                          </CFormLabel>
                          <CFormInput
                            type="text"
                            id="validationCustom01"
                            onChange={(event) => handleFormChange(event, index)}
                            value={form.item}
                            disabled
                            name="item"
                            required
                          />
                          <CFormFeedback invalid>
                            Please select item
                          </CFormFeedback>
                        </CCol>
                        <CCol md={4} sm={12} xs={12}>
                          <CFormSelect
                            aria-describedby="validationTooltip04Feedback"
                            feedbackInvalid="Please select type."
                            id="validationTooltip04"
                            label="Type"
                            disabled
                            name="type"
                            value={form.type}
                            onChange={(event) => handleFormChange(event, index)}
                            required
                          >
                            <option value="">Choose</option>
                            <option value="gold">Gold</option>
                            <option value="silver">Silver</option>
                          </CFormSelect>
                        </CCol>
                        <CCol lg={3} md={12} sm={12} xs={12}>
                          <CFormLabel htmlFor="validationCustom02">
                            Charges
                          </CFormLabel>
                          <CFormInput
                            type="number"
                            id="validationCustom02"
                            onChange={(event) => handleFormChange(event, index)}
                            value={form.charges}
                            disabled
                            name="charges"
                            placeholder="Charges"
                            required
                          />
                          <CFormFeedback invalid>
                            Please enter charges
                          </CFormFeedback>
                        </CCol>

                        <CCol md={1} sm={1} xs={2}>
                        </CCol>
                      </CRow>
                    );
                  })}
                  <CRow className="mt-3">
                    <CCol md={8} sm={12} xs={12}>
                    </CCol>
                    <CCol lg={3} md={12} sm={12} xs={12}>
                      <CFormLabel htmlFor="validationCustom03">
                        Total Charges :
                      </CFormLabel>
                      <CFormInput
                        type="number"
                        // onChange={toalcount}
                        id="validationCustom03"
                        disabled
                        name="total_number"
                        value={chargestotal}
                        placeholder="Total"
                        required
                      />
                      <CFormFeedback invalid>
                        Please enter Total Charges
                      </CFormFeedback>
                    </CCol>
                  </CRow>

                  <CRow className="mt-3">
                    <CForm>
                      <CCol md={8} sm={12} xs={12}>
                        <CFormLabel htmlFor="exampleFormControlTextarea1">
                          Note
                        </CFormLabel>
                        <CFormTextarea
                          id="exampleFormControlTextarea1"
                          name="note"
                          rows={3}
                          text=""
                          disabled
                          onChange={(e) => setNote(e.target.value)}
                          value={note}
                        ></CFormTextarea>
                      </CCol>
                    </CForm>
                  </CRow>

                  <CRow className="mt-3">
                    <CCol md={7} sm={12} xs={12}>
                      <CFormLabel htmlFor="validationCustom02">
                        {OrderPhoto &&
                          OrderPhoto != "" &&
                          OrderPhoto != "No Image" ? "Image" : ""}
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <CCol xs={12}>
                    {preview ? (
                      <img src={preview} className="imagePreview" />
                    ) : OrderPhoto &&
                      OrderPhoto != "" &&
                      OrderPhoto != "No Image" ? (
                      <img src={OrderPhoto} className="imagePreview" />
                    ) : null}
                  </CCol>

                  <CCol md={6} sm={12}>
                  </CCol>
                </CForm>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
}

export default View;