import { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormLabel,
  CRow,
  CHeaderDivider,
  CBreadcrumb,
  CBreadcrumbItem,
  CContainer,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilFolderOpen, cilInfo } from "@coreui/icons";
import "./TotalOutstanding.css";
import DataTable, { FilterComponent } from "react-data-table-component";
import { OutstandingList } from "../Helper/outstanding";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";
import { customerListApi } from "../Helper/customer";
import { fetchCustomerListApi, fetchCustomerListApi1 } from "../Helper/order";
import debounce from "debounce";
import { useQuery } from "@tanstack/react-query";


export default function TotalOutstanding() {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [perPage, setPerPage] = useState(10);
  const userData = useSelector((state) => state.userData);
  const [customerList, setCustomerList] = useState();
  const [listLoading, setListLoading] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [paidOption,setPaidOption]=useState("");
  const [totalRecords,setTotalRecords]=useState(0);
  const [offset, setOffset] = useState(0);
  const [debounceSearch, setDebounceSearch] = useState("");
 
  const columns = [
    {
      name: " No",
      selector: (row, index) => index + 1,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
    },
    {
      name: "Customer Phone",
      selector: (row) => row.customerPhone,
    },

    {
      name: "Total Outstanding",
      selector: (row) => row.totalOutStanding,
    },
    {
      name: "Total Paid",
      selector: (row) => row.totalPaid,
    },
    {
      name: "Balance",
      selector: (row) => row.remainingAmount,
    },
    // {
    //   name: 'Type',
    //   selector: row => row.type,
    // },
    {
      name: "View",
      selector: (row) => (
        <>
          <Link to={"/outstandingViewList/" + row.customerId}>
            <CButton
              color="secondary"
              title="View Outstanding"
              variant="outline"
              className="px-0 buttonsOrderPage "
            >
              <CIcon icon={cilFolderOpen} size="lg" />
            </CButton>
          </Link>
        </>
      ),
    },
  ];

  let userId = userData.userinfo.userId;

  const paymentOptions = [
    { value: "full paid", label: "Paid" },
    { value: "unpaid", label: "Unpaid" },
    { value: "partial paid", label: "Partially Paid" },
  ];

  function handlePageChange(page) {
    setListLoading(true);
    const offset = (page - 1) * perPage;
    setOffset(offset);
  }

  // useEffect(() => {
  //   handlePageChange(0);
  // }, []);

  // useEffect(() => {
  //   if (inputValue !== "") {
  //     setTimeout(() => {
  //       getCutomerlist();
  //     }, 200);
  //   }
  // }, [inputValue])


  const { data: outStandingData, isLoading: outStandingIsLoading, refetch: outStandingRefetch } = useQuery({
    queryKey: ['outstanding-list', offset],
    queryFn: () => OutstandingList(userId, offset < 0 ? 0 : offset, selectedCustomer, paidOption),
    gcTime: Infinity,
    staleTime: 0,
  });

  const paginationComponentOptions = {
    rowsPerPageText: "",
    noRowsPerPage: true,
  };

  const debouncedSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 800),
    []
  );

  const getCutomerlist = () => {
    fetchCustomerListApi1(debounceSearch).then((res) => {
      var result = res?.data;
      var customerLive = [];
      result.forEach((element) => {
        customerLive.push({
          value: element.customerId,
          label: element.customerName,
        });
      });
      setCustomerList(customerLive);
    });
  };

  useEffect(() => {
    getCutomerlist();
  }, [debounceSearch]);

  const handleCustomer = (e) => {
    setSelectedCustomer(e.value);
  };

  const handleInputChange = (value) => {
    setInputValue(value)
  }

  const handlePayment = (selectionOption) => {
    setPaidOption(selectionOption.value);
  };

  const handleSearch = async () => {
    await outStandingRefetch();
  };

  const handleClear = async (e) => {
    setPaidOption("");
    setSelectedCustomer("");
    setDebounceSearch("");
    await getCutomerlist();
    await outStandingRefetch();
  };

  const customStyles = {
    cells: {
      style: {
        borderRight: "1px solid #ddd",
        borderLeft: "1px solid #ddd"
      },
    },
    rdt_TableHeadRow: {
      style: {
        borderRight: "1px solid #ddd",
        borderLeft: "1px solid #ddd"
      },
    }
  };

  const handleSearchChange = (inputValue) => {
    if (inputValue) {
      debouncedSearch(inputValue);
    }
  }


  return (
    <div>
      <CHeaderDivider />
      <CContainer fluid>
        <CRow>
          <CCol xs={12}>
            <h5 className="main-title">Outstanding </h5>
          </CCol>
          <CCol xs={8}>
            <CBreadcrumb
              className="m-0 ms-2"
              style={{ "--cui-breadcrumb-divider": "'>'" }}
            >
              <CBreadcrumbItem>
                {" "}
                <Link to="/dashboard">Home</Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem actives>Total Outstanding</CBreadcrumbItem>
            </CBreadcrumb>
          </CCol>
          <CCol xs={4}></CCol>
        </CRow>
      </CContainer>

      <CRow className="justify-content-center">
        <CCol md={12} lg={12} sm={12}>
          <CCard style={{ margin: "10px" }} className="mb-4">
            <CCardHeader>
              <strong>Total Outstanding</strong>
            </CCardHeader>
            <CCardBody>
              <CRow className="">
                <CCol md={12}>
                  <CFormLabel>
                    <b>Filter:</b>
                  </CFormLabel>
                </CCol>

                <CCol md={3}>
                  <CFormLabel>Customer Name</CFormLabel>
                  <Select
                    options={customerList}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select Customer
                      </div>
                    }
                    className="text-start mb-3"
                    value={customerList?.find(
                      (obj) => obj.value === selectedCustomer
                    )}
                    onChange={handleCustomer}
                    onInputChange={handleSearchChange}
                    isClearable
                    isSearchable
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel>Payment</CFormLabel>
                  <Select
                    options={paymentOptions}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select Payments
                      </div>
                    }
                    value={
                      paymentOptions?.find((obj) => obj.value === paidOption) || ""
                    }
                    onChange={handlePayment}
                    isClearable
                  />
                </CCol>

                <br />
                <CCol md={12}>
                  <div className="text-center mt-2">
                    <CButton
                      color="success"
                      className="m-2 text-white"
                      onClick={handleSearch}
                    >
                      Search
                    </CButton>
                    <CButton
                      color="dark"
                      className="text-white"
                      onClick={handleClear}
                    >
                      Clear
                    </CButton>
                  </div>
                </CCol>
              </CRow>

              {/* <DataTable
                className=" mt-3 "
                columns={columns}
                data={data}
                highlightOnHover
                pagination
                paginationServer
                progressPending={listLoading}
                paginationRowsPerPageOptions={[]}
                paginationComponentOptions={paginationComponentOptions}
                paginationTotalRows={totalRecords}
                onChangePage={handlePageChange}
                customStyles={customStyles}
              /> */}

              <DataTable
                className="tableTopSpace "
                columns={columns}
                responsive={true}
                data={outStandingData?.data}
                progressPending={outStandingIsLoading}
                paginationTotalRows={outStandingData?.totalRecords}
                onChangePage={handlePageChange}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                customStyles={customStyles}
                paginationServer
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
}
