import { useState, useEffect, useMemo, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { Component } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormInput,
    CFormLabel,
    CInputGroup,
    CRow,
    CHeaderDivider,
    CBreadcrumb,
    CBreadcrumbItem,
    CContainer,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
} from "@coreui/react";
import "../../../src/components/Customerbilling/customerbilling.css";
import DataTable, { FilterComponent } from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
    cilFile,
    cilFolderOpen,
    cilInfo,
    cilLibraryAdd,
    cilPencil,
    cilSettings,
    cilTrash,
} from "@coreui/icons";
import {
    fetchCustomerListApi,
    orderListApi,
    OrderPhotoUpdate,
    OrderSendSms,
    deleteorderByIdApi,
    fetchCustomerListApi1,
    ItemNotPickedList,
} from "../Helper/order";
import swal from "sweetalert";
import Select from "react-select";
import debounce from "debounce";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "../..";

const customStyles = {
    cells: {
        style: {
            borderRight: "1px solid #ddd",
        },
    },
};

export default function ItemsNotPicked() {
    const [data, setData] = useState();
    const [filterStartDate, setfilterStartDate] = useState("");
    const [filterEndDate, setfilterEndDate] = useState("");
    const [orderList, setorderList] = useState([]);
    const [filterdata, setfilterdata] = useState(orderList);
    const [perPage, setPerPage] = useState(10);
    const [editOrderId, seteditOrderId] = useState(0);
    const [orderTotalRecord, setorderTotalRecord] = useState();
    const [filterOnCustomer, setfilterOnCustomer] = useState("");
    const [filterOnbillNo, setfilterOnbillNo] = useState();
    const [listLoading, setListLoading] = useState(false);
    const [customerList, setCustomerList] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [selectedSentType, setSelectedSentType] = useState("");
    const [visible, setVisible] = useState(false);
    const [imageError, setImageError] = useState("");
    const [image, setImage] = useState();
    const [preview, setPreview] = useState();
    const [smsSendFlag, setsmsSendFlag] = useState();
    // const [isBillPaid, setisBillPaid] = useState();
    const [orderPhoto, setOrderPhoto] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [orderIdForPhoto, setOrderIdForPhoto] = useState();
    const userData = useSelector((state) => state.userData);
    let navigate = useNavigate();
    let userId = userData.userinfo.userId;

    const [debounceSearch, setDebounceSearch] = useState('');
    const [offset, setOffset] = useState(0);

    const sentOptions = [{ value: "0", label: "Not Sent" }, { value: "1", label: "Sent" }];

    const getCutomerlist = () => {
        fetchCustomerListApi1(debounceSearch).then((res) => {
            var result = res?.data;
            var customerLive = [];
            result.forEach((element) => {
                customerLive.push({
                    value: element.customerId,
                    label: element.customerName,
                });
            });
            setCustomerList(customerLive);
        });

    };

    const debouncedSearch = useCallback(
        debounce((value) => setDebounceSearch(value), 800),
        []
    );

    useEffect(() => {
        getCutomerlist();
    }, [debounceSearch]);

    const handleSearchChange = (inputValue) => {
        if (inputValue) {
            debouncedSearch(inputValue);
        }
    }

    const handleCustomer = (e) => {
        setSelectedCustomer(e.value);
    };

    const handleInputChange = (value) => {
        setInputValue(value);
    }

    const handleSent = (e) => {
        //alert(e.value)
        setSelectedSentType(e.value);
        sessionStorage.setItem("selectedSentType-custbill", e.value);
    };

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true,
    };

    // const getorderlist = async (page) => {
    //     let startformattedDate = "";
    //     let endformattedDate = "";

    //     if (sessionStorage.getItem("filterStartDate-custbill") !== null && sessionStorage.getItem("filterEndDate-custbill") !== null) {
    //         let datestart = new Date(sessionStorage.getItem("filterStartDate-custbill"));
    //         startformattedDate = datestart.toLocaleDateString("es-CL", {
    //             day: "2-digit",
    //             month: "2-digit",
    //             year: "numeric",
    //         });
    //         let dateend = new Date(sessionStorage.getItem("filterEndDate-custbill"));
    //         endformattedDate = dateend.toLocaleDateString("es-CL", {
    //             day: "2-digit",
    //             month: "2-digit",
    //             year: "numeric",
    //         });
    //     }
    //     else {
    //         if (filterStartDate != "" && filterEndDate != "") {
    //             let datestart = new Date(filterStartDate);
    //             startformattedDate = datestart.toLocaleDateString("es-CL", {
    //                 day: "2-digit",
    //                 month: "2-digit",
    //                 year: "numeric",
    //             });
    //             let dateend = new Date(filterEndDate);
    //             endformattedDate = dateend.toLocaleDateString("es-CL", {
    //                 day: "2-digit",
    //                 month: "2-digit",
    //                 year: "numeric",
    //             });
    //         }
    //     }

    //     if (sessionStorage.getItem("selectedSentType-custbill") !== null) {
    //         var sentType = sessionStorage.getItem("selectedSentType-custbill")
    //     } else {
    //         sentType = selectedSentType
    //     }

    //     setListLoading(true);
    //     let customerId;
    //     if (sessionStorage.getItem("selectedCustomer-custbill") !== null) {
    //         customerId = sessionStorage.getItem("selectedCustomer-custbill")
    //     } else {
    //         customerId = selectedCustomer
    //     }
    //     await ItemNotPickedList(
    //         userData.userinfo.userId,
    //         page ? page : 0,
    //         startformattedDate,
    //         endformattedDate,
    //         customerId,
    //         sentType
    //     )
    //         .then(
    //             async (res) => {
    //                 setorderList(res.data);
    //                 setsmsSendFlag(res.data.smsSendFlag);
    //                 setData(res.data);
    //                 setListLoading(false);
    //                 setorderTotalRecord(res.totalRecords);
    //             },
    //             (err) => {
    //                 setListLoading(false);
    //             }
    //         )
    //         .catch(setListLoading(false));
    // };

    const { data: notPickedList, isLoading: notPickedListsLoading, refetch: notPickedRefetch } = useQuery({
        queryKey: ['items-not-picked-list', offset],
        queryFn: () => ItemNotPickedList(userData.userinfo.userId, offset ? offset : 0, filterStartDate, filterEndDate, selectedCustomer, selectedSentType),
        staleTime: 5 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
    });

    const debounceAPIRefetch = useCallback(
        debounce(async () => {
            await notPickedRefetch();
        }, 500),
        []
    )

    const handlePageChange = (page) => {
        let currentPage = page;
        var offset = (currentPage - 1) * perPage;
        setOffset(offset);
    };

    const handleSearch = () => {
        notPickedRefetch();
    }

    useEffect(() => {
        if (!image) {
            setPreview(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(image);
        if (image.type === "image/png" || image.type === "image/jpeg") {
            // console.log("ok")
            setPreview(objectUrl);
        } else {
            // console.log("notok");
            setPreview(null);
        }

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [image]);

    const handleFileChangeImage = (e) => {
        // console.log(e.target.files[0]);
        if (e.target.files) {
            if (
                e.target.files[0].type == "image/png" ||
                e.target.files[0].type == "image/jpeg"
            ) {
                setImageError("");
            } else {
                setImageError("Please select Image Type of png or jpg");
            }
            setImage(e.target.files[0]);
        }
    };

    const deleteorder = async (orderId) => {
        let payloadData = {
            // userId: userData.userinfo.userId,
            orderId: orderId,
        };

        swal({
            title: "Are you sure?",
            text: "Are you sure to delete Order?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancel", "Ok"],
        }).then((willDelete) => {
            if (willDelete) {
                deleteorderByIdApi(payloadData).then((res) => {
                    if (res.status === 200) {
                        swal("Success", res.message, "success").then((ok) => {
                            if (ok) {
                                queryClient.invalidateQueries(["items-not-picked-list"])
                            }
                        });
                    }
                });
            }
        });
    };

    const handleModal = (oid) => {
        setVisible(true);
        setOrderIdForPhoto(oid);
    };

    const handleSMSFlag = async (orderId) => {
        // console.log(orderId);
        let payloadData = {
            userId: userId,
            orderId: orderId,
        };
        await OrderSendSms(payloadData).then((res) => {
            if (res.status === 200) {
                swal("Success", res.message, "success").then((ok) => {
                    if (ok) {
                        window.location.reload();
                    }
                });
            } else {
                swal("Warning", res.message, "warning");
            }
        });
    };

    const uploadPhoto = (e) => {
        let formData = new FormData();
        formData.append("orderId", orderIdForPhoto);
        formData.append("orderPhoto", image);
        OrderPhotoUpdate(formData).then((res) => {
            if (res.status === 200) {
                swal("Success", res.message, "success").then((ok) => {
                    if (ok) {
                        window.location.reload();
                    }
                });
            } else {
                swal("Warning", res.message, "warning");
            }
        });
    };

    const handleCustomerOutstanding = (orderId, orderDate, customerId) => {
        // console.log(orderId, orderDate, customerId); 
    };

    const columns = [
        {
            name: " No",
            selector: (row, index) => index + 1,
            width: "50px"
        },
        {
            name: "Actions",
            allowOverflow: true,
            wrap: true,
            width: "150px",
            selector: (row) => (
                <>
                    <div className="button-customerorder">

                        <CButton
                            color="success"
                            variant="outline"
                            title="Add Outstanding"
                            style={{ marginRight: '8px', marginLeft: '0px !important' }}
                            className="ms-2 buttonsOrderPage w-100"
                            onClick={() =>
                                navigate("/actionoutstanding", {
                                    state: {
                                        orderId: row.orderId,
                                        orderDate: row.orderDate,
                                        customerId: row.customerId,
                                        customerName: row.customerName,
                                    },
                                })
                            }
                        >
                            <CIcon icon={cilLibraryAdd} size="lg" />
                        </CButton>

                        <CDropdown variant="btn-group" direction="dropup-center" >
                            <CDropdownToggle color="dark" className="btn-md"> <CIcon icon={cilSettings} size="lg" /></CDropdownToggle>
                            <CDropdownMenu className="topdropshow">

                                {
                                    row.isBillPaid === "1" && row.isComplete === 1 && row.isSent === 1 ? (
                                        <></>
                                    ) : (<>
                                        <Link to={"/order/" + row.orderId} className=" text-decoration-none" >
                                            <CDropdownItem >
                                                <CButton
                                                    color="dark"
                                                    title="Edit Order"
                                                    variant="outline"
                                                    className="px-0 buttonsOrderPage "
                                                >
                                                    <CIcon icon={cilPencil} size="lg" className="" />
                                                </CButton>
                                                <span className="ms-3 text-decoration-none text-dark" >Edit </span>
                                            </CDropdownItem>
                                        </Link>
                                    </>)
                                }


                                <Link to={"/view/" + row.orderId} className=" text-decoration-none">
                                    <CDropdownItem>
                                        <CButton
                                            color="secondary"
                                            title="View Order"
                                            variant="outline"
                                            className="px-0 buttonsOrderPage "
                                        >
                                            <CIcon icon={cilFolderOpen} size="lg" />
                                        </CButton>
                                        <span className="ms-3 text-decoration-none text-dark" >View</span>
                                        {/* <span  className="ms-3 text-decoration-none text-dark" ><CIcon icon={cilFolderOpen} size="lg" /> view</span> */}

                                    </CDropdownItem>
                                </Link>

                                <Link to={"https://testcplasersoldering.cplaser.in/opr/" + row.orderId} className=" text-decoration-none" target="_blank" >
                                    <CDropdownItem>
                                        <CButton
                                            color="dark"
                                            title="Print"
                                            variant="outline"
                                            className="px-0 buttonsOrderPage "
                                        >
                                            <CIcon icon={cilFile} size="lg" className="" />
                                        </CButton>
                                        <span className="ms-3 text-decoration-none text-dark" >Print</span>
                                    </CDropdownItem>
                                </Link>


                                <CDropdownItem href="#" onClick={() => deleteorder(row.orderId)}>
                                    <CButton
                                        color="danger"
                                        title="Delete Order"
                                        variant="outline"
                                        className="px-0 buttonsOrderPage "
                                    >
                                        <CIcon icon={cilTrash} size="lg" />
                                    </CButton>
                                    <span className="ms-3">Delete</span>
                                </CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                    </div>
                </>
            ),
        },
        {
            name: "Order No",
            selector: (row) => row.orderId,
            width: "85px"
        },
        {
            name: "Customer Name",
            wrap: true,
            selector: (row) =>
                row.customerName + " (Ph. " + row.customerPhone + ")"
            ,
        },
        // {
        //   name: "Customer Phone",
        //   selector: (row) => row.customerPhone,
        //   width: "125px"
        // },
        {
            name: "Complete",
            selector: (row) =>
            (
                <>
                    {row.isComplete == "1" ? (
                        <>
                            <span
                                className="badge p-2"
                                style={{
                                    backgroundColor: "lightgreen",
                                    color: "#006400",
                                    fontSize: "0.75rem",
                                }}
                            >
                                Yes
                            </span>
                        </>
                    ) : (
                        <span
                            className="badge p-2"
                            style={{
                                backgroundColor: "lightpink",
                                color: "red",
                                fontSize: "0.75rem",
                            }}
                        >
                            No
                        </span>
                    )}
                    &nbsp;
                </>
            ),
            width: "90px"
        },
        {
            name: "Sent",
            selector: (row) =>
            (
                <>
                    {row.isSent == "1" ? (
                        <>
                            <span
                                className="badge p-2"
                                style={{
                                    backgroundColor: "lightgreen",
                                    color: "#006400",
                                    fontSize: "0.75rem",
                                }}
                            >
                                Yes
                            </span>
                        </>
                    ) : (
                        <span
                            className="badge p-2"
                            style={{
                                backgroundColor: "lightpink",
                                color: "red",
                                fontSize: "0.75rem",
                            }}
                        >
                            No
                        </span>
                    )}
                    &nbsp;
                </>
            ),
            width: "90px"
        },
        {
            name: "Bill paid",
            selector: (row) => (
                <>
                    {
                        row.isBillPaid === 1 ? (
                            <>
                                <span
                                    className="badge p-2"
                                    style={{
                                        backgroundColor: "lightgreen",
                                        color: "#006400",
                                        fontSize: "0.75rem",
                                    }}
                                >
                                    Yes
                                </span>
                            </>
                        ) : (
                            <>
                                <span
                                    className="badge p-2"
                                    style={{
                                        backgroundColor: "lightpink",
                                        color: "red",
                                        fontSize: "0.75rem",
                                    }}
                                >
                                    No
                                </span>
                            </>
                        )
                    }
                </>
            ),
            width: "90px"
        },
        {
            name: "Order Date",
            wrap: true,
            selector: (row) => row.orderDate,
            width: "105px"
        },
        {
            name: "Total Charges",
            selector: (row) => row.totalCharges,
            width: "110px"
        }

    ];

    const handleClear = (e) => {
        setDebounceSearch("")
        setfilterStartDate("");
        setfilterEndDate("");
        setSelectedCustomer("");
        setSelectedSentType("");
        debounceAPIRefetch();
    };

    return (
        <div>
            <CHeaderDivider />
            <CContainer fluid>
                <CRow>
                    <CCol xs={12}>
                        <h5 className="main-title" style={{ marginLeft: "-6px" }}> Item's Not Picked </h5>
                    </CCol>
                    <CCol xs={8}>
                        <CBreadcrumb
                            className="m-0 ms-2"
                            style={{ "--cui-breadcrumb-divider": "'>'" }}
                        >
                            <CBreadcrumbItem style={{ marginLeft: "-12px" }}>
                                <Link to="/dashboard">Home</Link>
                            </CBreadcrumbItem>
                            <CBreadcrumbItem actives>Item's Not Picked</CBreadcrumbItem>
                        </CBreadcrumb>
                    </CCol>
                </CRow>
            </CContainer>
            <br />

            <CRow className="justify-content-center">
                <CCol md={12} lg={12}>
                    <CCard>
                        <CCardHeader>
                            <strong>Filter:</strong>
                        </CCardHeader>
                        <CCardBody>
                            <CRow className="justify-content-start">

                                <CCol md={4} sm={12} >
                                    <CFormLabel>Customer Name</CFormLabel>
                                    <Select
                                        options={customerList}
                                        placeholder={
                                            <div className="select-placeholder-text">
                                                Select Customer
                                            </div>
                                        }
                                        className="text-start mb-3"
                                        value={customerList?.find(
                                            (obj) => obj.value === selectedCustomer
                                        ) || ""}
                                        onChange={handleCustomer}
                                        onInputChange={handleSearchChange}
                                    />
                                </CCol>

                                <CCol xs={12}>
                                    <div className="text-center ">
                                        <CButton
                                            color="success"
                                            className="m-2 text-white"
                                            onClick={() => {
                                                handleSearch()
                                            }}
                                            type="button"
                                        >
                                            Search
                                        </CButton>
                                        <CButton
                                            color="dark"
                                            type="submit"
                                            className="text-white"
                                            onClick={() => {
                                                handleClear();
                                            }}
                                        >
                                            Clear
                                        </CButton>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                    <CModal
                        alignment="center"
                        scrollable
                        visible={visible}
                        onClose={() => {
                            setVisible(false);
                            setOrderIdForPhoto("");
                            setImage("");
                            setImageError("");
                        }}
                    >
                        <CModalHeader>
                            <CModalTitle>Order Add Image</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <CContainer>
                                <CRow className="mt-3">
                                    <CCol md={7} sm={12} xs={12}>
                                        <CFormLabel htmlFor="validationCustom02">Image</CFormLabel>
                                        <CFormInput
                                            name="image"
                                            type="file"
                                            id="validationTextarea"
                                            aria-label="file example"
                                            onChange={handleFileChangeImage}
                                        />
                                    </CCol>
                                    <CCol xs={12} className="mt-3">
                                        {preview ? (
                                            <>
                                                <img src={preview} className="imagePreview" />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </CCol>
                                    <span className="text-danger">{imageError}</span>
                                    <button
                                        className="btn btn-success mt-3"
                                        onClick={(e) => {
                                            uploadPhoto();
                                        }}
                                    >
                                        Upload Photo
                                    </button>
                                </CRow>
                            </CContainer>
                        </CModalBody>
                    </CModal>
                    <br />
                    <CCard>
                        <CCardBody className="table-responsive">
                            <DataTable
                                className="tableTopSpace "
                                columns={columns}
                                responsive={true}
                                data={notPickedList?.data}
                                progressPending={notPickedListsLoading}
                                paginationTotalRows={notPickedList?.totalRecords}
                                onChangePage={handlePageChange}
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                customStyles={customStyles}
                                paginationServer
                            />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    );
}
