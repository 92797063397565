import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import "flatpickr/dist/themes/material_green.css";
import "react-datalist-input/dist/styles.css";
import "./BillStyles.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CWidgetStatsF,
  CWidgetStatsB,
  CRow,
  CHeaderDivider,
  CBreadcrumb,
  CBreadcrumbItem,
  CContainer,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilTrash, cilPlus } from "@coreui/icons";
import "./ordertable.css";
import moment from "moment";
import {
  checkIsReceived,
  createOrderApi,
  deleteOrderItem,
  fetchCustomerListApi,
  fetchCustomerListApi1,
  getOrderPrint,
  ItemCustomerList,
  OrderUpdate,
} from "../Helper/order";
import { useSelector } from "react-redux";
import { customerApi } from "../Helper/customer";
import { useParams } from "react-router-dom";
import { orderEditByIdApi } from "../Helper/order";
import Select from "react-select";
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import { queryClient } from "../..";
import debounce from "debounce";

function OrderTable() {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [BillModal, setBillModal] = useState(false);
  const [itemVisible, setItemVisible] = useState(false);
  const [preview, setPreview] = useState();
  const [formErrors, setFormErrors] = useState([]);
  const [formFields, setFormFields] = useState([
    {
      item: "",
      charges: 0,
      type: "",
      orderItemId: "-",
      isComplete: "0",
      isSent: "",
      isReceived: "0",
      itemErr: false,
      chargesErr: false,
      orgIsReceived: false,
      sentDate: ""
    },
  ]);

  const [customernameError, setCustomernameError] = useState(false);
  const [chargestotal, setchargestotal] = useState(0);
  const [customerlist, setCustomerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [note, setNote] = useState("");
  const [isBillPaid, setIsBillPaid] = useState(false);
  const [orderDate, setorderDate] = useState(moment());
  const [chargesError, setchargesError] = useState();
  const [selectedCustomer, setSelectedCusomer] = useState();
  const [itemorderList, setitemorderList] = useState([]);
  const [additemError, setAdditemError] = useState(false);
  const [OrderPhoto, setOrderPhoto] = useState();
  const [message, setMessage] = useState("")
  let { Id } = useParams();
  const [name, setName] = useState();
  const [contact, setContact] = useState();
  const [customeNoteError, setcustomeNoteError] = useState();
  const [nameErr, setNameErr] = useState();
  const [contactErr, setContactErr] = useState();
  const [customerName, setCustomerName] = useState();
  const [customerPhone, setCustomerPhone] = useState("");
  const [OrderId, setOrderId] = useState();
  const [printData, setPrintData] = useState();
  const printableAreaRef = useRef();
  const [search, setSearch] = useState("")
  const userData = useSelector((state) => state.userData);
  const [totalchargesErr, setTotalchargesErr] = useState(false);
  const [debounceSearch, setDebounceSearch] = useState("");
  const [debounceItemSearch, setDebounceItemSearch] = useState("");
  let userId = userData.userinfo.userId;

  const getPrintData = async () => {
    getOrderPrint(Id).then((res) => {
      if (res.status === 200) {
        setPrintData(res.data);
      }
    })
  }

  const isEditMode = !!Id;

  const debouncedSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 800),
    []
  );

  const debouncedItemSearch = useCallback(
    debounce((value) => setDebounceItemSearch(value), 800),
    []
  );

  const getCustomerList = () => {
    fetchCustomerListApi1(debounceSearch).then(
      (res) => {
        var data = res.data;
        var customerLive = [];
        data.forEach((element) => {
          customerLive.push({
            value: element.customerId,
            label:
              element.customerName + " " + "(" + element.customerPhone + ")",
          });
        });
        setCustomerList(customerLive);
      },
      (err) => {
      }
    );
  }

  useEffect(() => {
    if (isEditMode) {
      getorderDataForEdit();
    }
  }, [isEditMode]);

  useEffect(() => {
    getCustomerList();
  }, [debounceSearch]);

  // useEffect(() => {
  //   if (search !== "") {
  //     const debounceTimeout = setTimeout(() => {
  //       fetchitemlist();
  //     }, 500);
  //     return () => clearTimeout(debounceTimeout);
  //   }
  // }, [search])

  useEffect(() => {
    fetchitemlist();
  }, [debounceItemSearch]);


  const fetchitemlist = () => {
    ItemCustomerList(search).then(
      (res) => {
        // console.log(res);
        var data = res.data;
        var itemlistLive = [];
        data.forEach((element) => {
          itemlistLive.push({
            value: element.itemName,
            label: element.itemName,
          });
        });
        setitemorderList(itemlistLive);
      },
      (err) => {
        // console.log(err);
      }
    );
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    if (image.type === "image/png" || image.type === "image/jpeg") {
      // console.log("ok")
      setPreview(objectUrl);
    } else {
      // console.log("notok");
      setPreview(null);
    }

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();
    formFields.map((e, index) => {
      if (e.item == "") {
        let data = [...formFields];
        data[index]["itemErr"] = true;
        setFormFields(data);
      }
      if (e.charges <= 0) {
        let data = [...formFields];
        data[index]["chargesErr"] = true;
        setFormFields(data)
      }
    });

    const formm = event.currentTarget;
    if (formm.checkValidity() === false) {
      event.preventDefault();
      setLoading(false)
    } else {

      let totalpay = 0;
      formFields.map((e) => {
        totalpay = totalpay + parseFloat(e.charges);
      });

      if (chargestotal <= 0 || !chargestotal || chargestotal !== totalpay) {
        setTotalchargesErr(true)
        setLoading(false)
        return
      }
      else {
        setTotalchargesErr(false)
      }

      if (selectedCustomer && selectedCustomer != "0") {
        if (Id === undefined) {
          const formData = new FormData();
          formData.append("userId", userData.userinfo.userId);
          formData.append("customerId", selectedCustomer);
          formData.append("orderItems", JSON.stringify(formFields));
          formData.append("totalCharges", chargestotal);
          formData.append("note", note);
          formData.append("orderPhoto", image ?? "");
          formData.append("orderDate", orderDate);
          formData.append("isBillPaid", isBillPaid ? 1 : 0);

          await createOrderApi(formData).then((res) => {
            if (res.status === 200) {
              setOrderId(res.orderId)
              getOrderPrint(res.orderId).then((res) => {
                setPrintData(res.data);
              })
              setBillModal(true)
              setMessage(res.message);
              setOrderId(res.orderId);
              queryClient.invalidateQueries(["order-data-list"]);
            } else {
              swal("Error", res.message, "warning");
            }
          });
          setLoading(false);
          setImageError(false);
        } else {
          const formData = new FormData();
          formData.append("userId", userData.userinfo.userId);
          formData.append("customerId", selectedCustomer);
          formData.append("orderItems", JSON.stringify(formFields));
          formData.append("totalCharges", chargestotal);
          formData.append("note", note);
          formData.append("orderPhoto", image ?? "");
          formData.append("orderDate", orderDate);
          formData.append("orderId", Id);
          formData.append("isBillPaid", isBillPaid ? 1 : 0);
          await OrderUpdate(formData).then((res) => {
            if (res.status === 200) {
              getPrintData()
              setBillModal(true)
              setMessage(res.message);
              queryClient.invalidateQueries(["order-data-list"]);
              queryClient.invalidateQueries(["customer-data"]);
              queryClient.invalidateQueries(["dashboard-cards"]);
              queryClient.invalidateQueries(["unsent-orderdata"]);
              queryClient.invalidateQueries(["items-not-picked-list"]);
              queryClient.invalidateQueries(["received-data-list"]);
              queryClient.invalidateQueries(["outstanding-list"]);
              queryClient.invalidateQueries(["weekly-order-list"]);
              queryClient.invalidateQueries(["yesterday-order-list"]);
            } else {
              swal("Error", res.message, "warning");
            }
          });
          setLoading(false);
          setImageError(false);
        }
      } else {
        // console.log("customername",customername)
        if (image && (image.type === "image/png" || image.type === "image/jpeg")) {
          setImageError(false);
          setLoading(false)
        } else if (image) {
          setImageError(true);
          setLoading(false)
        }
        else {
          setImageError(false)
          setLoading(false)
        }
        if (!selectedCustomer || selectedCustomer == "0") {
          setCustomernameError(true);
          setLoading(false)
        }
        setLoading(false);
      }
    }
    setValidated(true);
    setLoading(false);
  };


  const handleFormChange = (event, index, item = "") => {
    if (item === "item") {
      debouncedItemSearch(event.target.value);
    };

    setSearch(event.target.value);
    let data = [...formFields];
    data[index][name] = event.target.value;
    data[index][item !== "" ? item : event.target.name] = item !== "" ? event.target.value : event.target.value;
    setFormFields(data);
    if (name === 'charges') {
      const errors = [...formErrors];
      if (event.target.value === '0' || event.target.value === '0.0' || event.target.value === '0.00' || event.target.value === '0.000') {
        errors[index] = 'Charges must be grater than 0.';
      } else {
        errors[index] = '';
      }
      setFormErrors(errors);
    }

    if (event.target.name === 'charges') {
      if (event.target.value.match(/^0(\.0{1,100})?$/) || event.target.value.match(/^(-)?0+(\.0{1,3})?$/)) {
        data[index]['chargesErr'] = true;
      } else {
        data[index]['chargesErr'] = false;
      }
    }

    if (item === "item") {
      if (!event.target.value || event.target.value < 0 || event.target.value === "") {
        data[index]["itemErr"] = true;
      } else {
        data[index]["itemErr"] = false;
      }
    }


    if (item !== "item") {
      if (
        event.target.name === "charges" &&
        event.target.value &&
        event.target.value !== 0
      ) {
        setchargesError(true);
      } else {
        setchargesError(false);
      }
      // if (event.target.name === "charges") {
      //   let totalcharges = 0;
      //   formFields.map((e) => {
      //     totalcharges = totalcharges + parseFloat(e.charges);
      //   });
      //   setchargestotal(totalcharges);
      // }
    }
  };

  const handleCalculate = () => {
    let totalcharges = 0;
    formFields.map((e) => {
      totalcharges = totalcharges + parseFloat(e.charges);
    });
    setchargestotal(totalcharges);
    setTotalchargesErr(false);
  }

  const formreset = () => {
    window.location.reload();
    setImage(null);
    setImageError(false);
    setPreview(null);
    setSelectedCusomer("");
    setchargestotal(0);
    setFormFields([
      {
        item: "",
        charges: "",
        type: "",
        orderItemId: "-",
        isComplete: "0",
        isSent: "",
        itemErr: false,
        chargesErr: false,
        isReceived: "0",
      },
    ]);
  };

  const addFields = () => {
    let object = {
      item: "",
      charges: "",
      type: "",
      orderItemId: "-",
      isComplete: "0",
      isSent: "0",
      isReceived: "0",
      itemErr: false,
      chargesError: false
    };
    setFormFields([...formFields, object]);
  };

  const removeFields = (index, charges, orderItemId, isReceived) => {
    if (isReceived === true) {
      swal("Warning", "You can not delete this item after all payment is received!", "warning");
    } else {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Cancel", "Ok"],
      }).then((willDelete) => {
        if (willDelete) {
          let data = [...formFields];
          data.splice(index, 1);
          setFormFields([...data]);
          setchargestotal(chargestotal - charges);
          deleteOrderItem({ orderItemId: orderItemId }).then((res) => {
            if (res.status === 200) {
              swal("Success", res.message, "success");
            } else {
              swal("Warning", res.message, "warning");
            }
          });
        }
      });
    }
  };

  const validationForm = (inputName, value) => {
    if (inputName === "note" && value && value.length > 500) {
      setcustomeNoteError(true);
    } else {
      setcustomeNoteError(false);
    }
    if (inputName === "Item Add" && value && value.length == "") {
      setAdditemError(true);
      setValidated(false);
      return false;
    } else {
      setAdditemError(false);
    }
    setValidated(true);
  };

  const handleFileChangeImage = (e) => {
    if (e.target.files) {

      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpeg"
      ) {
        setImage("")
        setImageError(false);
      } else {
        setImageError(true);
      }
      setImage(e.target.files[0]);
    }
  };

  const getorderDataForEdit = async () => {
    let orderId = Id;
    await orderEditByIdApi(orderId)
      .then(
        async (res) => {
          if (res.status === 200) {
            var items = [];
            res.data.orderItems.forEach((element) => {
              // console.log("element", element);
              items.push({
                item: element.item,
                type: element.type,
                charges: element.charges,
                orderItemId: element.orderItemId,
                isComplete: element.isComplete === 1 ? true : false,
                isSent: element.isSent === 1 ? true : false,
                isReceived: element.isReceived === 1 ? true : false,
                orgIsReceived: element.isReceived === 1 ? true : false,
                itemErr: false,
                sentDate: element.sentDate
              });
              setFormFields(items);
            });
            setNote(res.data.note);
            setorderDate(res.data.orderDate);
            // console.log(res.data.orderDate);
            setCustomerList([{ value: res.data.customerId, label: res?.data?.customerName }])
            setSelectedCusomer(res.data.customerId);
            setCustomerName(res.data.customerName);
            // console.log(res?.data?.customerPhone)
            setCustomerPhone(res.data.customerPhone)
            setchargestotal(res.data.totalCharges);
            setOrderPhoto(res.data.orderPhoto);
            setIsBillPaid(res.data.isBillPaid == "1" ? true : false);
          }
        },
        (err) => { }
      )
      .catch();
  };

  const handleAddCustomer = (e) => {
    let validate = true;
    e.preventDefault();
    let data = {
      userId: userId,
      customerName: name,
      customerPhone: contact,
    };
    if (data.customerName?.trim() === "" || data.customerName === undefined) {
      setNameErr("Please Enter Valid Name");
      validate = false;
    }
    if (data.customerPhone?.trim() === "" || data.customerPhone === undefined) {
      setContactErr("Please Inter Contact Number");
      validate = false;
    }

    if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-.]?([0-9]{4})$/.test(
        data.customerPhone
      )
    ) {
      setContactErr("Please enter 10 digit number");
      validate = false;
    }

    if (validate) {
      customerApi(data).then((res) => {
        // console.log(res);
        if (res.status === 200) {
          swal("Success", res.message, "success").then((Ok) => {
            if (Ok) {
              getCustomerList();
              setName("");
              setContact("");
              setVisible(false);
            }
          });
        } else {
          swal("Warning", res.message, "warning");
        }
      });
    }
  };

  const handleIsReceived = (charges, orderItemId, index, isReceived) => {
    if (Id && orderItemId !== "-") {
      let payloadData = {
        orderId: Id,
        customerId: selectedCustomer,
        orderItemId: orderItemId,
        isReceived: 0,
        amount: charges,
      };

      checkIsReceived(payloadData).then((res) => {
        if (res.status === 200) {
          let data = [...formFields];
          data[index]["isReceived"] = !isReceived;
          setFormFields(data);
        } else if (res.status === 300) {
          let data = [...formFields];
          data[index]["isReceived"] = !isReceived;
          setFormFields(data);
          swal("Warning", res.msg, "warning");
        }
      });
    } else {
      let NUMCharges = Number(charges)
      if (NUMCharges === 0) {
        swal("Warning", "Charges should be more than 0", "warning");
      } else {
        let data = [...formFields];
        // Toggle the editability flag
        data[index]["isReceived"] = !isReceived;
        setFormFields(data);
      }
    }
  };

  const handleDateChange = (date) => {
    setorderDate(date);
  };

  function handleCustomer(e) {
    setCustomerName(e.label);
    setSelectedCusomer(e.value);
    setCustomernameError("");
  }

  const handleInputChange = (value) => {
    debouncedSearch(value);
  }

  const handlePrintClick = () => {
    const w = window.open();
    if (printableAreaRef.current?.innerHTML) {
      w?.document.write(printableAreaRef.current.innerHTML);
      w?.print();
    }
    w?.close();
  };

  return (
    <div>
      <CHeaderDivider />
      <CRow>
        <CCol md={12}>
          <h5 className="main-title ms-4">Order </h5>
        </CCol>
        {Id ? (
          <CCol xs={8}>
            <CBreadcrumb
              className="m-0 ms-4"
              style={{ "--cui-breadcrumb-divider": "'>'" }}
            >
              <CBreadcrumbItem>
                <Link to="/dashboard">Home</Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem actives>Edit Order</CBreadcrumbItem>
            </CBreadcrumb>
          </CCol>
        ) : (
          <CCol xs={8}>
            <CBreadcrumb
              className="m-0 ms-4"
              style={{ "--cui-breadcrumb-divider": "'>'" }}
            >
              <CBreadcrumbItem>
                <Link to="/dashboard">Home</Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem actives>New Order</CBreadcrumbItem>
            </CBreadcrumb>
          </CCol>
        )}

        <CCol xs={4}>
          <div className="text-end">
            {/* <Link to="/customerbilling" className="me-3"> */}
            <CButton
              color="info"
              size="sm"
              className="px-4 text-end text-white "
              type="button"
              style={{ marginTop: "-52px" }}
              onClick={(e) => window.history.back()}
            >
              Back
            </CButton>
            {/* </Link> */}
          </div>
        </CCol>
      </CRow>
      {/* </CContainer> */}
      <br />

      <CRow className="justify-content-center">
        <CCol md={12} lg={12} sm={12}>
          <CCard style={{ margin: "10px" }} className="mb-4">
            {Id ? (
              <CCardHeader>
                <strong>Edit Order</strong>
              </CCardHeader>
            ) : (
              <CCardHeader>
                <strong>New Order</strong>
              </CCardHeader>
            )}

            <CCardBody>
              <br />
              <div>
                <CForm
                  className="row g-3 needs-validation"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  method="post"
                >
                  <CRow className="mb-3">
                    <CCol md={7} sm={12} xs={12}>
                      <CFormLabel>Customer</CFormLabel>
                      &nbsp;&nbsp;
                      <div className="avatar bg-primary avatar-sm text-white">
                        <label
                          onClick={() => setVisible(!visible)}
                          className="text-white p-1"
                          type="button"
                          size="sm"
                          color="primary"
                        >
                          <CIcon icon={cilPlus} className="text-white" />
                        </label>
                      </div>
                      &nbsp;
                      <div className="">
                        <Select
                          options={customerlist}
                          className="text-start"
                          placeholder={
                            <div className="select-placeholder-text">
                              Select options
                            </div>
                          }
                          value={customerlist?.find(
                            (obj) => obj.value === selectedCustomer
                          )}
                          onChange={handleCustomer}
                          onInputChange={handleInputChange}
                          isSearchable={true}
                          isDisabled={isBillPaid === 1 ? true : false}
                        />
                      </div>
                      <CFormFeedback invalid>
                        Please Choose a Customer Name.
                      </CFormFeedback>
                      {customernameError === true ? (
                        <>
                          <CFormFeedback className="errorMessage mb-3">
                            Please Choose a Customer Name.
                          </CFormFeedback>
                        </>
                      ) : null}
                    </CCol>
                    <CCol md={1} sm={1} xs={2}>
                    </CCol>

                    <CCol md={4} sm={12}>
                      <CFormLabel>Order Date</CFormLabel>
                      <CInputGroup className="mb-3">
                        <Datetime
                          value={orderDate}
                          onChange={handleDateChange}
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm A"
                        />
                      </CInputGroup>
                    </CCol>

                    {Id ? null : (
                      <CCol md={12} sm={12} xs={12}>
                        <div className="form-check ">
                          <input
                            className="form-check-input"
                            onChange={() => {
                              setIsBillPaid(!isBillPaid);
                            }}
                            type="checkbox"
                            name="checkbox"
                            checked={isBillPaid == 1 || isBillPaid == "1"}
                            id="flexCheckChecked"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckChecked"
                          >
                            Is Bill Paid
                          </label>
                        </div>
                      </CCol>
                    )}

                    <CModal
                      alignment="center"
                      scrollable
                      visible={visible}
                      onClose={() => setVisible(false)}
                    >
                      <CModalHeader>
                        <CModalTitle>Order Add Customer</CModalTitle>
                      </CModalHeader>
                      <CModalBody>
                        <CContainer>
                          <CRow>
                            <CCol md={12} sm={12} lg={12}>
                              <form className="">
                                <div className="">
                                  <label>Name</label>
                                  <input
                                    type="name"
                                    className="form-control"
                                    onChange={(e) => {
                                      setName(e.target.value);
                                      setNameErr("");
                                    }}
                                    placeholder="Enter Name"
                                  />
                                  <span className="text-danger">{nameErr}</span>
                                </div>
                                <div className="mt-3">
                                  <lable>Contact</lable>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      setContact(e.target.value);
                                      if (String(e.target.value).length != 10) {
                                        setContactErr(
                                          "Please Enter 10 Digit Number"
                                        );
                                      } else {
                                        setContactErr("");
                                      }
                                    }}
                                    placeholder="Enter Contact"
                                  />
                                  <span className="text-danger">
                                    {contactErr}
                                  </span>
                                </div>
                                <div className="mt-3">
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleAddCustomer}
                                  >
                                    Add
                                  </button>
                                </div>
                              </form>
                              <br />
                            </CCol>
                          </CRow>
                        </CContainer>
                      </CModalBody>
                    </CModal>
                  </CRow>
                  <CCol md={12} sm={12} xs={12}>
                    <CCardHeader className="header-item">
                      <strong>Add Items</strong>
                      &nbsp; &nbsp;
                    </CCardHeader>
                  </CCol>

                  <br />
                  <br />

                  {formFields.map((form, index) => {
                    return (
                      <div className="item-background">
                        <CRow key={index}>
                          <CCol md={5} sm={12} xs={11} className="input-item">
                            <CFormLabel className="mb-3">Item :</CFormLabel>
                            <div className="">

                              <input
                                list="browsers"
                                name="item"
                                id="item"
                                className="form-select"
                                value={form.item}
                                placeholder="Select Item"
                                onChange={(event) => {
                                  handleFormChange(event, index, "item")
                                }}
                              />
                              <datalist id="browsers">
                                {itemorderList.map((data, index) => (
                                  <option key={index} name="item" value={data.value} />
                                ))}
                              </datalist>

                              {form.itemErr === true ? (
                                <>
                                  <CFormFeedback className="itemError">
                                    Please Select Item.
                                  </CFormFeedback>
                                </>
                              ) : null}
                            </div>
                            <CCol
                              className="text-complet-checkbox"
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <div className="form-check mt-3 ">
                                <CFormLabel
                                  htmlFor="validationCustom01"
                                  className="label-check-edit"
                                >
                                  Is Complete
                                </CFormLabel>
                                <input
                                  className="form-check-input"
                                  disabled={form.isSent === "1" ? true : false}
                                  style={{
                                    fontSize: "20px",
                                    marginLeft: "-20px",
                                  }}
                                  type="checkbox"
                                  onChange={() => {
                                    let data = [...formFields];
                                    data[index]["isComplete"] =
                                      !form.isComplete;
                                    setFormFields(data);
                                  }}
                                  name="isComplete"
                                  checked={
                                    form.isComplete == 1 ? true : false
                                  }
                                  value=""
                                  id="flexCheckChecked"
                                />
                              </div>
                            </CCol>
                          </CCol>
                          &nbsp;
                          <CCol md={4} sm={12} xs={12} className=" type-input">
                            <CFormLabel className="mb-3">Type :</CFormLabel>
                            <CFormSelect
                              aria-describedby="validationTooltip04Feedback"
                              feedbackInvalid="Please select type."
                              id="validationTooltip04"
                              name="type"
                              value={form.type}
                              onChange={(event) =>
                                handleFormChange(event, index)
                              }
                              required
                            >
                              <option value="">Choose</option>
                              <option value="gold">Gold</option>
                              <option value="silver">Silver</option>
                            </CFormSelect>

                            <CCol
                              className="text-complet-checkbox"
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <div className="form-check mt-3 ">
                                <CFormLabel
                                  htmlFor="validationCustom01"
                                  className="label-check-edit"
                                >
                                  Is Sent {form.isSent == "1" && form?.sentDate != "" && `(${form?.sentDate})`}
                                </CFormLabel>
                                <input
                                  className="form-check-input"
                                  disabled={form.isComplete === "1" ? true : false}
                                  style={{
                                    fontSize: "20px",
                                    marginLeft: "-20px",
                                  }}
                                  type="checkbox"
                                  onChange={() => {
                                    let data = [...formFields];
                                    data[index]["isSent"] =
                                      !form.isSent;
                                    setFormFields(data);
                                  }}
                                  name="isSent"
                                  checked={
                                    form.isSent == "1" ? true : false
                                  }
                                  value=""
                                  id="flexCheckChecked"
                                />
                              </div>
                            </CCol>

                          </CCol>
                          &nbsp;
                          <CCol
                            md={4}
                            sm={12}
                            xs={10}
                            className="Charges-input"
                          >
                            <CFormLabel
                              htmlFor="validationCustom02"
                              className="mb-3"
                            >
                              Charges :
                            </CFormLabel>
                            <CFormInput
                              type="number"
                              disabled={form.isReceived === true ? true : false}
                              id="validationCustom02"
                              onChange={(event) =>
                                handleFormChange(event, index)
                              }
                              value={form.charges}
                              name="charges"
                              placeholder="Charges"
                              min="0"
                            />
                            {form.chargesErr == true ? (
                              <>
                                <CFormFeedback className="itemError">
                                  Please Enter valid amount.
                                </CFormFeedback>
                              </>
                            ) : null}
                            <CCol
                              className="text-complet-checkbox"
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <div className="form-check mt-3 ">
                                <CFormLabel
                                  htmlFor="validationCustom01"
                                  className="label-check-edit"
                                >
                                  Is Received
                                </CFormLabel>
                                <input
                                  className="form-check-input"
                                  disabled={(form.isReceived === true && form.orgIsReceived === true) ? true : false}
                                  style={{
                                    fontSize: "20px",
                                    marginLeft: "-20px",
                                  }}
                                  type="checkbox"
                                  onChange={() => {
                                    handleIsReceived(form.charges, form.orderItemId, index, form.isReceived)
                                  }}
                                  name="isReceived"
                                  checked={
                                    form.isReceived == true ? true : false
                                  }
                                  value=""
                                  id="flexCheckChecked"
                                />
                              </div>
                            </CCol>
                          </CCol>
                          &nbsp;
                          <CCol
                            md={1}
                            sm={12}
                            xs={2}
                            style={{ width: "2.33333% " }}
                            className="mb-3"
                          >
                            <CButton
                              color="primary"
                              disabled={formFields.length === 1 ? true : false}
                              onClick={() => {
                                removeFields(
                                  index,
                                  form.charges,
                                  form.orderItemId,
                                  form.isReceived
                                );
                              }}
                              className="removeButton"
                            >
                              <CIcon icon={cilTrash} />
                            </CButton>
                          </CCol>
                        </CRow>
                      </div>
                    );
                  })}

                  <CRow className="mt-3">
                    <CCol md={8} sm={12} xs={12} className="button-plus">
                      <CButton
                        color="danger"
                        className="text-white mt-4"
                        onClick={addFields}
                        type="button"
                      >
                        <CIcon icon={cilPlus} className="text-white " /> Items
                      </CButton>
                    </CCol>


                    <CCol md={4} sm={12} xs={10} className="total-Charges d-flex flex-column gap-2">
                      <div>
                        <CButton
                          color="primary"
                          className="text-white mt-4 px-2 py-1"
                          type="button"
                          onClick={handleCalculate}
                        >
                          Calculate
                        </CButton>
                      </div>
                      <div>
                        <CFormLabel htmlFor="validationCustom03">
                          Total Charges :
                        </CFormLabel>
                        <CFormInput
                          type="number"
                          id="validationCustom03"
                          name="total_number"
                          value={chargestotal}
                          placeholder="Total"
                          readonly
                        />
                        {totalchargesErr == true ? (
                          <>
                            <CFormFeedback className="itemError">
                              Please calculate total amount.
                            </CFormFeedback>
                          </>
                        ) : null}
                      </div>
                    </CCol>
                  </CRow>
                  <CCol md={8} sm={12} xs={12}></CCol>
                  <hr />
                  <CRow className="mt-3">
                    <CForm>
                      <CCol md={7} sm={12} xs={12}>
                        <CFormLabel htmlFor="exampleFormControlTextarea1">
                          Note
                        </CFormLabel>
                        <CFormTextarea
                          id="exampleFormControlTextarea1"
                          name="note"
                          rows={3}
                          text=""
                          onChange={(e) => {
                            setNote(e.target.value);
                            validationForm(e.target.name, e.target.value);
                          }}
                          value={note}
                        // required
                        ></CFormTextarea>
                        {customeNoteError === true ? (
                          <>
                            <CFormFeedback className="errorMessage-customer">
                              Please Enter 500 Characters
                            </CFormFeedback>
                          </>
                        ) : null}
                      </CCol>
                    </CForm>
                  </CRow>

                  <CRow className="mt-3">
                    <CCol md={7} sm={12} xs={12}>
                      <CFormLabel htmlFor="validationCustom02">
                        Image
                      </CFormLabel>
                      <CFormInput
                        name="image"
                        type="file"
                        id="validationTextarea"
                        aria-label="file example"
                        onChange={handleFileChangeImage}

                      />
                      {imageError === true ? (
                        <>
                          <CFormFeedback className="errorMessage">
                            Please upload image only
                          </CFormFeedback>
                        </>
                      ) : null}
                    </CCol>
                  </CRow>
                  <CCol xs={12}>
                    {preview ? (
                      <img src={preview} className="imagePreview" />
                    ) : OrderPhoto &&
                      OrderPhoto != "" &&
                      OrderPhoto != "No Image" ? (
                      <img src={OrderPhoto} className="imagePreview" />
                    ) : null}
                  </CCol>

                  <CCol md={12} sm={12}
                    className="d-flex justify-content-end align-items-end gap-3"
                  >
                    <CButton
                      color="primary"
                      disabled={imageError || loading ? true : false}
                      type="submit"
                      onClick={() => {
                        if (!selectedCustomer || selectedCustomer === "0") {
                          setCustomernameError(true)
                          setLoading(false)
                        }
                        else {
                          setCustomernameError(false)
                          setLoading(false)
                        }
                        // !selectedCustomer || selectedCustomer === "0"
                        //   ? setCustomernameError(true)
                        //   : setCustomernameError(false);
                      }
                      }
                    >
                      {loading ? "wait.." : "Submit"}
                    </CButton>
                    {Id ? null : (
                      <CButton
                        color="link"
                        className="text-decoration-none"
                        onClick={formreset}
                      >
                        Reset
                      </CButton>
                    )}
                  </CCol>
                </CForm>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CModal
          className="modal"
          alignment="center"
          scrollable
          visible={BillModal}
          onClose={() => setBillModal(false)}
        >

          <CModalTitle className="text-success mt-2 ms-2">Success</CModalTitle>
          <hr />
          <CModalBody>
            <CContainer>
              <CRow>
                <CCol>
                  <div className="ticket billTable" style={{ fontFamily: "Times New Roman", fontSize: "12px", minWidth: "100%" }} >
                    <p className="centered"> <b>CP LASER SOLDERING</b></p>
                    <hr className="m-0" />
                    <b className="">{printData?.customerName} {printData?.customerPhone ?? ""} </b><br />
                    <b className="">Order Id : {Id ? Id : printData?.orderId} </b><br />
                    <b className="">Order Date : {printData?.orderDate} </b><br />
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th className="quantity">Sr</th>
                          <th className="description">Items</th>
                          <th className="description">Type</th>
                          <th className="price">₹</th>
                        </tr>
                      </thead>
                      <tbody>
                        {printData?.orderItems?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td className="quantity">{index + 1}</td>
                              <td className="description">{data.item}</td>
                              <td className="description">{data.type}</td>
                              <td className="">{data.charges}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td className="quantity"></td>
                          <td className="quantity"></td>
                          <td className="description">TOTAL</td>
                          <td className="price"><b>{chargestotal}</b></td>
                        </tr>
                      </tfoot>
                    </table>
                    {
                      printData?.preOrderArray ? (<>
                        <b className="">Previous Outstanding </b><br /><br />
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              <th className="quantity">Sr</th>
                              <th className="description">Order ID</th>
                              <th className="description"></th>
                              <th className="price">₹</th>
                            </tr>
                          </thead>
                          <tbody>

                            {
                              printData?.preOrderArray?.map((data, index) => {
                                return <tr>
                                  <td className="quantity">{index + 1}</td>
                                  <td className="quantity">{data.orderId}</td>
                                  <td className="description">R.AMOUNT</td>
                                  <td className="price">{data.remainingAmount}</td>
                                </tr>
                              })
                            }
                          </tbody>
                          <tfoot>
                            <tr>
                              <td className="quantity"></td>
                              <td className="quantity"></td>
                              <td className="description">G.TOTAL</td>
                              <td className="price"><b>{printData?.preOrderTotalCharges}</b></td>
                            </tr>
                          </tfoot>
                        </table>
                      </>) : (<>

                      </>)
                    }
                    <p className="centered">Thanks for your purchase!</p>
                  </div>
                </CCol>
              </CRow>
            </CContainer>
          </CModalBody>
          <CModalFooter>
            {
              Id !== undefined ? (<button className="btn btn-info me-2" onClick={() => { navigate("/order/print/" + Id) }}>Print</button>) : <button className="btn btn-info me-2" onClick={() => { navigate("/order/print/" + OrderId) }}>Print</button>
            }
            <button className="btn btn-danger" onClick={() => { navigate("/customerbilling") }}>Go Back</button>
          </CModalFooter>
        </CModal>
      </CRow>
    </div >
  );


}

export default OrderTable;