import React, { useState, useEffect } from "react";
import "./TotalOutstanding/TotalOutstanding.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormInput,
  CFormFeedback,
  CCardHeader,
  CInputGroup,
  CInputGroupText,
  CRow,
  CContainer,
  CCardGroup,
  CFormLabel,
  CHeaderDivider,
  CBreadcrumb,
  CBreadcrumbItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilLockLocked,
  cilUser,
  cilInfo,
  cilPencil,
  cilTrash,
} from "@coreui/icons";
import DataTable from "react-data-table-component";
import AppBreadcrumb from "./AppBreadcrumb";
import {
  customerApi,
  customerByIdApi,
  customerListApi,
  updateCustomerByIdApi,
  deleteCustomerByIdApi,
  newCustomerListApi,
} from "./Helper/customer";
import swal from "sweetalert";
import Select from "react-select";
import { fetchCustomerListApi } from "./Helper/order";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "..";

const styleheader = {
  marginTop: "-24px",
  marginLeft: "-25px",
  marginRight: "-25px",
};

const customStyles = {
  cells: {
    style: {
      borderRight: "1px solid #ddd",
    },
  },
};

export default function FormComponent() {
  const [validated, setValidated] = useState(false);
  const [Contact, setcontact] = useState();
  const [editCustomerId, setEditCustomerId] = useState(0);
  const [name, setname] = useState();
  const [loading, setLoading] = useState(false);
  const [customeNamerError, setcustomeNamerError] = useState(false);
  const [customerPhoneError, setcustomerPhoneError] = useState(false);
  const [custmerList, setCustomerList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [custmerTotalRecord, setCustomerTotalRecord] = useState();
  const [perPage, setPerPage] = useState(10);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [customerSearchList, setCustomerSearchList] = useState();
  const [search, setSearch] = useState();
  const [offset, setOffset] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const userData = useSelector((state) => state.userData);
  const columns = [
    {
      name: " No",
      selector: (row, index) => index + 1,
    },
    {
      name: "Customer Name",
      // allowOverflow:true,
      wrap: true,
      selector: (row) => row.customerName,
    },
    {
      name: "Contact Number",
      selector: (row) => row.customerPhone,
    },
    {
      name: "View",
      allowOverflow: true,
      selector: (row) => (
        <>
          <div className="button-customerorder">
            {/* <CButton color="primary" variant="outline" className="px-0 buttonsOrderPage "> 
          <CIcon icon={cilInfo} size="lg"  />
        </CButton>&nbsp;&nbsp; */}
            <CButton
              title="Edit customer"
              color="dark"
              variant="outline"
              onClick={() => getCustomerDataForEdit(row.customerId)}
              className="px-0 buttonsOrderPage "
            >
              <CIcon icon={cilPencil} size="lg" />
            </CButton>
            &nbsp;&nbsp;
            <CButton
              title="Delete customer"
              color="danger"
              variant="outline"
              onClick={() => deleteCustomer(row.customerId)}
              className="px-0 buttonsOrderPage "
            >
              <CIcon icon={cilTrash} size="lg" />
            </CButton>
            &nbsp;&nbsp;
          </div>
        </>
      ),
    },
  ];

  const { data: customerData, isLoading: customerDataIsLoading, refetch: customerDataRefetch } = useQuery({
    queryKey: ['customer-data', offset],
    queryFn: () => newCustomerListApi(userData?.userinfo?.userId, offset ? offset : 0, searchQuery),
    staleTime: 5 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
  });


  // const getcustomer = async (page) => {
  //   if (userData.userinfo.userId) {
  //     setListLoading(true);
  //     await customerListApi(userData.userinfo.userId, page ? page : 0, "")
  //       .then(
  //         async (res) => {
  //           console.log("customer:", res.data)
  //           setCustomerList(res.data);
  //           setListLoading(false);
  //           setCustomerTotalRecord(res.totalRecords);
  //         },
  //         (err) => {
  //           setListLoading(false);
  //         }
  //       )
  //       .catch();
  //   }
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    let currentPage = page;
    const offset = (currentPage - 1) * perPage;
    setOffset(offset);
  };

  const set1 = () => {
    setname("");
    setcontact("");
    setEditCustomerId(0);
  };
  // let regx = /^[A-Za-z\s]*$/;
  const handleSubmit = (event) => {
    event.preventDefault();
    const formm = event.currentTarget;
    if (formm.checkValidity() === false) {
      event.preventDefault();
    } else {
      event.preventDefault();
      event.stopPropagation();
      if (Contact.length == 10) {
        if (editCustomerId && editCustomerId > 0 && Contact.length === 10) {
          // console.log("code for update")
          let payloadData = {
            userId: userData.userinfo.userId,
            customerName: name,
            customerId: editCustomerId,
            customerPhone: Contact,
          };
          setLoading(true);
          updateCustomerByIdApi(payloadData)
            .then(
              async (res) => {
                // console.log(" success");
                // console.log("res", res);
                if (res.status == 500) {
                  swal("Customer", res.message, "error");
                } else {
                  setValidated(false);
                  set1();
                  // swal("Customer",  res.message, "success")
                  // getcustomer(0);
                  queryClient.invalidateQueries(["customer-data"]);
                  setCurrentPage(1);
                }
                setLoading(false);
              },
              (err) => {
                // console.log("error");
                setLoading(false);
                swal("Customer", "invalid Customer", "success");
              }
            )
            .catch();
        } else {
          // console.log("code for create")
          let payloadData = {
            userId: userData.userinfo.userId,
            customerName: name,
            customerPhone: Contact,
          };
          setLoading(true);
          customerApi(payloadData)
            .then(
              async (res) => {
                // console.log(" success");
                // console.log("res", res);
                if (res.status == 500) {
                  swal("Customer", res.message, "error");
                } else {
                  setValidated(false);
                  set1();
                  swal("Customer", res.message, "success");
                  // getcustomer(0);
                  queryClient.invalidateQueries(["customer-data"]);
                }
                setLoading(false);
              },
              (err) => {
                // console.log("error");
                setLoading(false);
                swal("Customer", "invalid Customer", "success");
              }
            )
            .catch();
        }
      } else {
        // if (regx.test(name) === false) {
        //   setcustomeNamerError(true);
        // }
        if (Contact.length != 10) {
          setcustomerPhoneError(true);
        }
      }
    }
    setValidated(true);
  };

  const validationForm = (inputName, value) => {
    if (inputName === "number" && value && value.length !== 10) {
      setcustomerPhoneError(true);
      setValidated(false);
      return false;
    } else {
      setcustomerPhoneError(false);
    }
    setValidated(true);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "",
    noRowsPerPage: true,
  };

  const getCustomerDataForEdit = async (editCustomerId) => {
    await customerByIdApi(userData.userinfo.userId, editCustomerId)
      .then(
        async (res) => {
          if (res.data) {
            setcontact(res.data.customerPhone);
            setEditCustomerId(editCustomerId);
            setname(res.data.customerName);
          }
        },
        (err) => { }
      )
      .catch();
  };
  const deleteCustomer = async (deleteCustomerId) => {
    let payloadData = {
      userId: userData.userinfo.userId,
      customerId: deleteCustomerId,
    };
    swal({
      title: "Are you sure to delete Customer?",

      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Ok"],
    })
      .then(
        (willDelete) => {
          if (willDelete) {
            deleteCustomerByIdApi(payloadData).then(async (res) => {
              if (res.status === 200) {
                swal("Success", res.message, "success").then((ok) => {
                  if (ok) {
                    queryClient.invalidateQueries(["customer-data"]);
                    let custmerListt = custmerList.filter((e) => {
                      if (e.customerId != deleteCustomerId) {
                        return e;
                      }
                    });
                    //  console.log("custmerListt",custmerListt)
                    setCustomerList([...custmerListt]);
                    setCustomerTotalRecord(custmerTotalRecord - 1);
                  }
                });
              }
            });
          }
        },
        (err) => { }
      )
      .catch();
  };
  const handleCustomer = (e) => {
    setSelectedCustomer(e.value);
  };
  const handleClear = async (e) => {
    setSearch("");
    setSearchQuery("");
    // await customerDataRefetch();
  };
  const handleSearch = async (e) => {
    setSearchQuery(search);
    // await customerDataRefetch();
  }

  // useEffect(() => {
  //   customerDataRefetch();
  // }, [searchQuery, customerDataRefetch]);

  // To refetch when the "clear" action is taken
  useEffect(() => {
    if (searchQuery === "") {
      customerDataRefetch();
    } else {
      customerDataRefetch();
    }
  }, [searchQuery, customerDataRefetch]);

  return (
    <div>
      <CHeaderDivider />

      <CContainer fluid>
        <CRow className="mb-3">
          <CCol xs={12}>
            <h5 className="main-title" >Customer</h5>
          </CCol>
          <CCol xs={8}>
            <CBreadcrumb
              className="m-0 ms-2"
              style={{ "--cui-breadcrumb-divider": "'>'" }}
            >
              <CBreadcrumbItem>
                {" "}
                <Link to="/dashboard">Home</Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem actives>Customer</CBreadcrumbItem>
            </CBreadcrumb>
          </CCol>
          <CCol xs={4}>
            <div class="text-end">
              {/* <Link to="/dashboard"><CButton color="warning"  size="sm" className="px-4 text-end text-white" style={{marginTop: "-52px"}} type="button">
         Back
      </CButton></Link> */}
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol md={12} sm={12} lg={5} style={{ marginTop: "-15px" }}>
            <CCard className="p-4 mt-3">
              <CCardHeader style={styleheader}>
                <strong>Add Customer </strong>
              </CCardHeader>
              <CCardBody>
                <CForm
                  className="row g-3 needs-validation"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  method="post"
                  encType="multipart/form-data"
                >
                  {/* <h4>Add Customer</h4>  */}

                  <CFormLabel className="mb-0 text-start" style={{ marginLeft: "-6px" }}>Name :</CFormLabel>
                  <CFormInput
                    placeholder="Name"
                    className="mt-0 text-start"
                    name="name"
                    type="text"
                    onChange={(e) => {
                      setname(e.target.value);
                      validationForm(e.target.name, e.target.value);
                    }}
                    value={name}
                    required
                  />
                  <CFormFeedback invalid>Please Enter Name.</CFormFeedback>
                  {customeNamerError === true ? (
                    <>
                      <CFormFeedback className="errorMessage-customer">
                        Please Enter Name.
                      </CFormFeedback>
                    </>
                  ) : null}
                  <br />

                  <CFormLabel className="mb-0 text-start" style={{ marginLeft: "-6px" }}>
                    {" "}
                    Contact :
                  </CFormLabel>
                  <CFormInput
                    type="phone"
                    className="mt-0 text-start"
                    name="number"
                    placeholder="Enter phone number"
                    value={Contact}
                    onChange={(e) => {
                      setcontact(e.target.value);
                      validationForm(e.target.name, e.target.value);
                    }}
                    required
                  />
                  <CFormFeedback invalid>
                    Please Enter 10 digit Number.
                  </CFormFeedback>
                  {customerPhoneError === true ? (
                    <>
                      <CFormFeedback className="errorMessage-customer">
                        Please Enter 10 digit Number.
                      </CFormFeedback>
                    </>
                  ) : null}

                  <CCol xs={12} className="m-0">
                    <br />
                    <CButton
                      color="primary"
                      className="px-4"
                      type="submit"
                    //  disabled={loading}
                    >
                      {/* {loading ? "Wait..":"Update"} */}
                      {/* Update */}
                      Submit
                    </CButton>
                  </CCol>
                </CForm>
              </CCardBody>
            </CCard>
            <br />
          </CCol>

          <CCol md={12} lg={7}>
            <CCard>
              <CCardHeader>
                <strong>Customer List</strong>
              </CCardHeader>
              <CCol md={12} className="p-3" sm={12} >
                <input type="text" placeholder="Search Customer" value={search} onChange={(e) => { setSearch(e.target.value) }} className="form-control" />
              </CCol>
              <CCol xs={12}>
                <div className="text-center ">
                  <CButton
                    color="success"
                    className="m-2 text-white"
                    onClick={() => handleSearch()}
                    type="button"
                  >
                    Search
                  </CButton>
                  <CButton
                    color="dark"
                    type="submit"
                    className="text-white"
                    onClick={() => {
                      handleClear();
                    }}
                  >
                    Clear
                  </CButton>
                </div>
              </CCol>
              <CCardBody>
                <DataTable
                  className="tableTopSpace  border border-table"
                  columns={columns}
                  progressPending={customerDataIsLoading}
                  responsive={true}
                  data={customerData?.data}
                  paginationTotalRows={customerData?.totalRecords}
                  onChangePage={handlePageChange}
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  customStyles={customStyles}
                  paginationServer
                  paginationDefaultPage={currentPage}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}
