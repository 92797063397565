import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filterState : {
        searchQuery: "",
        showStartDate: "",
        showEndDate: "",
        startDate: "",
        endDate: "",
        customerId: "",
        sentType: ""
    }
}

const filterReducer = createSlice({
    name: "filterState",
    initialState,
    reducers: {
        addFilterState: (state, action) => {
            state.filterState = { 
                ...state.filterState, 
                ...action.payload 
            };
        },

        removeFilterState: (state) => {
            state.filterState = {
                searchQuery: "",
                showStartDate: "",
                showEndDate: "",
                startDate: "",
                endDate: "",
                customerId: "",
                sentType: ""
            }
        }
    }
})

export const { addFilterState, removeFilterState } = filterReducer.actions;

export default filterReducer.reducer;